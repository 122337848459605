/* eslint-disable react/prop-types */
// @ts-check

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyLabel } from '../../../lib/currency';
import { convertToUsdDecimal } from '../../../lib/prices';

/**
 * @typedef {{
 *  amounts: {
 *    piPoints: number,
 *    chips: number,
 *  }
 * }} ToastNotificationData
 */

/**
 * @typedef {{
 *  data: ToastNotificationData
 * }} ToastNotification
 */

/**
 * @typedef {{
 *  notification: ToastNotification
 * }} NotificationToastDonationThankYouProps
 */

export const NotificationToastDonationThankYou = (
	/** @type {NotificationToastDonationThankYouProps} */
	{ notification },
) => {
	const { t } = useTranslation();
	const {
		data: {
			amounts,
		},
	} = notification;

	const amount = useMemo(() => amounts?.piPoints || 0, [amounts]);

	return t('Toast.Donation.donationThankYou', { amount: convertToUsdDecimal(amount), currencyLabel });
};
